import { DialogService, DialogSettings } from "aurelia-dialog";
import { inject, autoinject } from "aurelia-framework";
import $ from "jquery";
import moment from "moment";
import { OrganizationService } from "../../services/OrganizationService";
import { OrganizationSearchTerms } from "models/OrganizationSearchTerms";
import { OrganizationViewModelDto } from "models/OrganizationViewModelDto";
import { OrganizationGridItem } from "./OrganizationGridItem";
import { Grid } from "resources/grid/grid";
import { AuthService } from "auth-service";
import { SignalrWrapper } from "signalrwrapper";
import { YesNoDialog } from "../../resources/yes-no-dialog/yes-no-dialog"
import { YesNo } from "../../resources/yes-no-dialog/YesNo";

import Highcharts from 'highcharts';
import addMore from "highcharts/highcharts-more";
import addDrilldown from "highcharts/modules/drilldown";
import addNoData from "highcharts/modules/no-data-to-display";
import addTreemap from "highcharts/modules/treemap";
import addTreegraph from "highcharts/modules/treegraph";
import addAccesibility from "highcharts/modules/accessibility";
import addExporting from "highcharts/modules/exporting";


@autoinject
export class ManageOrganizations {
    organizationSearchTerms = new OrganizationSearchTerms(undefined, undefined, 1, 10);
    grid: Grid<OrganizationViewModelDto, OrganizationGridItem>;
    signalreventhandlers: any = {};
    viewGraph: boolean = false;

    constructor(public organizationService: OrganizationService,
        public signalr: SignalrWrapper,
        public authService: AuthService,
        public dialogService: DialogService) {
        this.signalreventhandlers = {
            "OrganizationDeleted": this.onOrganizationDeleted,
            "OrganizationDeleteFailed": this.onOrganizationDeleteFailed
        };

        this.grid = new Grid<OrganizationViewModelDto, OrganizationGridItem>(
            this.organizationSearchTerms,
            this.organizationService.search,
            this.mapDtoToGridItem
        );
    }

    async attached() {
        const options: any = {
            locale: {
                format: 'DD/M/YYYY hh:mm:ss A'
            },
            minYear: 2019,
            autoUpdateInput: false, // initially empty
            showDropdowns: true,
            timePicker: true,
            timePickerSeconds: true,
            singleDatePicker: true
        };

        for (const key in this.signalreventhandlers) {
            if (this.signalreventhandlers.hasOwnProperty(key)) {
                this.signalr.on(key, this.signalreventhandlers[key]);
            }
        }
    
        await this.grid.search();
    }

    async show() {
        this.grid = new Grid<OrganizationViewModelDto, OrganizationGridItem>(
            this.organizationSearchTerms,
            this.organizationService.search,
            this.mapDtoToGridItem
        );

        await this.grid.search();

        addMore(Highcharts)
        addDrilldown(Highcharts)
        addNoData(Highcharts)
        addTreemap(Highcharts)
        addTreegraph(Highcharts)
        addAccesibility(Highcharts)
        addExporting(Highcharts)

        var chart = Highcharts.chart('highcharts-container', {});
        chart.destroy();

        this.viewGraph = false;
    }

    async showAll() {
        this.grid = new Grid<OrganizationViewModelDto, OrganizationGridItem>(
            this.organizationSearchTerms,
            this.organizationService.searchAllRecords,
            this.mapDtoToGridItem
        );

        await this.grid.search();

        this.viewGraph = false;
    }

    async showGraph(height: number = 400) {
        this.grid = new Grid<OrganizationViewModelDto, OrganizationGridItem>(
            this.organizationSearchTerms,
            this.organizationService.searchAllRecords,
            this.mapDtoToGridItem
        );

        await this.grid.search();

        addMore(Highcharts)
        addDrilldown(Highcharts)
        addNoData(Highcharts)
        addTreemap(Highcharts)
        addTreegraph(Highcharts)
        addAccesibility(Highcharts)
        addExporting(Highcharts)

        const data = [];
        for (var i = 0; i < this.grid.items.length; i++) {
            data.push({
                id: this.grid.items[i].id.toString(),
                parent: this.grid.items[i].parent.id.toString(),
                name: this.grid.items[i].description.toString()
            });
        }

        Highcharts.chart('highcharts-container', {
            chart: {
                inverted: false,
                height: height,
                marginBottom: 0
            },
            title: {
                text: 'Organization Graph',
                align: 'center'
            },
            series: [
                {
                    data,
                    tooltip: {
                        pointFormat: '{point.name}'
                    },
                    marker: {
                        radius: 6
                    },
                    type: 'treegraph',
                }
            ]
        });

        this.viewGraph = true;
    }

    increaseHeight() {
        var container = document.getElementById('highcharts-container');
        var styles = getComputedStyle(container);
        var height = styles.getPropertyValue('height').split('px')[0];
        var newHeight = parseInt(height) + 200;

        this.showGraph(newHeight);
    }

    decreaseHeight() {
        var container = document.getElementById('highcharts-container');
        var styles = getComputedStyle(container);
        var height = styles.getPropertyValue('height').split('px')[0];
        var newHeight = parseInt(height) - 200 < 400 ? 400 : parseInt(height) - 200;

        this.showGraph(newHeight);
    }

    detached() {
        for(const key in this.signalreventhandlers) {
            if (this.signalreventhandlers.hasOwnProperty(key)) {
                this.signalr.off(key, this.signalreventhandlers[key]);
            }
        }
    }

    mapDtoToGridItem = (dto: OrganizationViewModelDto) => {
        return new OrganizationGridItem(
            dto.id,
            dto.organizationType,
            dto.organizationTypeDescription,
            dto.description,
            dto.reportingFrequency,
            dto.reportingFrequencyDescription,
            dto.parent,
            dto.parentDescription,
            dto.timestamp,
            false,
            this.authService ? this.authService.isSystemAdmin : false,
            dto.details
        );
    }

    get isViewGraph() {
        return this.viewGraph;
    }

    get canDelete() {
        return this.authService.isSystemAdmin == true;
    }

    async deleteConfirmed(item: OrganizationGridItem) {
        await this.organizationService.delete(item.id);
        item.isDeleting = true;
        return;
    }

    async deleteConfirmationDialog(item: OrganizationGridItem) {
        await this.dialogService.open({
            viewModel: YesNoDialog,
            model: new YesNo("Are you sure you want to delete?", "This operation cannot be undone.")
        }).whenClosed((response) => {
            if (response.wasCancelled) {
                return;
            } else {
                return this.deleteConfirmed(item);
            }
        });
    }

    async delete(item: OrganizationGridItem) {
        return this.deleteConfirmationDialog(item);
    }

    onOrganizationDeleted = (organizationId: number) => {
        const deletedItemIndex = this.grid.items.findIndex(item => item.id === organizationId);
        if (deletedItemIndex !== -1) {
            this.grid.items[deletedItemIndex].isDeleting = false;
            this.grid.items.splice(deletedItemIndex, 1);
        }
        toastr.success("Deleted successfully");
    }

    onOrganizationDeleteFailed = (e: { $values: string[] }) => {
        toastr.error(e.$values.join(", "));
    }
}
